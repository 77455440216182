.banner {
    background-image: url('/src/assets/images/background/1111.jpeg'); 
    background-size: cover;
    background-position: center;
    padding: 120px 20px; /* Adjust padding as needed */
    color: #fff; /* Text color to contrast with the background image */
    position: relative;
    min-height: 500px; /* Ensure the banner has a minimum height */
    margin-top: 25px;
}

.banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.6); Add a semi-transparent overlay for better text readability */
    z-index: 1;
}

.container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
}

.banner__content {
    max-width: 600px; /* Limit the content width */
    text-align: left; /* Left align the text content */
}

.banner .title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
    color: #fff !important; 
}

.banner .compass {
    display: block;
    font-size: 2.5rem;
    margin-top: 10px;
    color: #ffd700; /* Change color for emphasis */
}

.banner .fs-20 {
    font-size: 1.25rem;
    margin-bottom: 30px;
    line-height: 1.6;
}

.banner .partner h6 {
    margin-top: 40px;
    font-size: 1.25rem;
    font-weight: normal;
}

/* Minimalistic button styling */
.banner .custom-button {
    background: none !important;
    color: #ffd700 !important;
    font-size: 1.25rem !important;
    padding: 10px 30px !important;
    border: 2px solid #ffd700 !important;
    border-radius: 5px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    text-decoration: none !important;
    display: inline-block !important;
}

.banner .custom-button:hover {
    background-color: #ffd700 !important;
    color: #000 !important;
}
/* Adjust styles for different screen sizes */
@media (max-width: 768px) {
    .banner {
        padding: 80px 15px;
        min-height: 400px;
        background-position: top center;
    }
    
    .banner__content {
        max-width: 100%;
    }

    .banner .title {
        font-size: 2.5rem;
    }

    .banner .compass {
        font-size: 2rem;
    }

    .banner .fs-20 {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .banner {
        padding: 60px 10px;
        min-height: 350px;
    }
    
    .banner .title {
        font-size: 2rem;
    }
    
    .banner .compass {
        font-size: 1.8rem;
    }

    .banner .fs-20 {
        font-size: 0.9rem;
    }
}