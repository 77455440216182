// src/components/Popup.scss
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .popup-options {
    margin-top: 20px;
  }
  
  .popup-options button {
    background-color: transparent;  /* Transparent background */
  color: #000000;                 /* Custom color for text */
  border: 2px solid #000000;      /* Border color */
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  border-radius: 5px;             /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  }
  
  .popup-close {
    margin-top: 20px;
    padding: 10px;
    cursor: pointer;
  }
  