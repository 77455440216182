/**
  * Name: Rockie | Crypto Exchange HTML Template
  * Version: 1.0.1
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/
@use "./scss/" as *;
@use "./scss/components/" as *;

// @import "../bootstrap/css/bootstrap.min.css";
// @import "../../assets/style.css";
// @import "../dist/aos.css";

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #00416A;
  --bg: #fff;
  --bg1: #fff;
  --onsurface: #23262f;
  --surface: #f7f7f7;
  --line: #e5e5e5;
  --text: #777e90;
  --second: #58bd7d;
}
.is_dark {
  --bg: #141416;
  --bg1: #222630;
  --onsurface: #fff;
  --surface: #18191d;
  --line: #23262f;
  --text: #b1b5c3;
}
body {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
  overflow-x: hidden;
  color: var(--text);
  background: var(--bg);
}
button {
  font-family: "DM Sans", sans-serif;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
  letter-spacing: -0.2px;
}
a {
  text-decoration: none;
  color: unset;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  color: var(--onsurface);
  &:hover {
    color: var(--primary);
  }
}

button {
  color: var(--onsurface);
  transition: all 0.3s ease-in-out;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  color: var(--onsurface);
}
.compass {
  font-weight: lighter;
  color: #00416A; /* Change the color to your desired color */
}
.new_banner {
  margin-top: 70px;/* Change the color to your desired color */
}
.new-icon {
  color: #00416A; /* Change the color to your desired color */
}
h2,
.h2 {
  font-size: 64px;
}
h3,
.h3 {
  font-size: 48px;
  letter-spacing: -1px;

  @include mobile {
    font-size: 32px;
  }
}
h4,
.h4 {
  font-size: 40px;
}
h5,
.h5 {
  font-size: 36px;
}
h6,
.h6 {
  font-size: 24px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 30px;
}
.fs-36 {
  font-size: 36px;
}
.fs-42 {
  font-size: 42px;
}
.mr-0 {
  margin-right: 0;
}
.pt-128 {
  padding-top: 128px;
}
.mt--16 {
  margin-top: -16px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-17 {
  margin-top: 17px;
}
.mt--23 {
  margin-top: -23px;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-9 {
  margin-top: 9px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-19 {
  margin-top: 19px;
}
.mt-21 {
  margin-top: 21px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-29 {
  margin-top: 29px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-51 {
  margin-top: 51px;
}
.mt-52 {
  margin-top: 52px;
}
.mt-53 {
  margin-top: 53px;
}
.mt-61 {
  margin-top: 61px;
}
.mt-67 {
  margin-top: 67px;
}
.mt-104 {
  margin-top: 104px;
}
.mt-107 {
  margin-top: 107px;
}
.mt-200 {
  margin-top: 200px;
}
.mt-223 {
  margin-top: 223px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-13 {
  margin-bottom: 13px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-29 {
  margin-bottom: 29px;
}
.mb-31 {
  margin-bottom: 31px;
}
.mb-33 {
  margin-bottom: 33px;
}
.mb-37 {
  margin-bottom: 37px;
}
.mb-41 {
  margin-bottom: 41px;
}
.mb-43 {
  margin-bottom: 43px;
}
.mb-46 {
  margin-bottom: 46px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-53 {
  margin-bottom: 53px;
}
.mb-58 {
  margin-bottom: 58px;
}
.mr-28 {
  margin-right: 28px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-24 {
  padding-top: 24px;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.row > * {
  padding-left: 15px;
  padding-right: 15px;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

.bg {
  background: var(--surface);
}
.interactive {
  background: #2e72d2;
}
.critical {
  background: #d33535;
}
.color-critical {
  color: #d33535;
}
.warning {
  background: #00416A;
}
.success {
  background: #58bd7d;
}
.color-success {
  color: #58bd7d;
}
.color-white {
  color: #fff;
}
.hightlight {
  background: #1e58c8;
}
.decorative {
  background: linear-gradient(216.56deg, #383fde 5.32%, #8444d5 94.32%);
}

@keyframes move5 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 100;
  background-color: var(--primary-color);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
  opacity: 0.3;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid var(--primary-color);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 100;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}
body[data-magic-cursor="hide"] .mouse-cursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}
.d-flex {
  display: flex;
}
.left {
  text-align: left;
}
.App {
  text-align: center;
}

.App button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}