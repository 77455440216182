/* src/components/Chatbot.css */
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 90%;  /* Full width on smaller screens */
  max-width: 450px;  /* Limit width on larger screens */
  height: 60vh;  /* Height responsive to viewport height */
  max-height: 450px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: none;
  flex-direction: column;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.chatbot-container.open {
  display: flex;
  transform: scale(1);
}

.chatbot-header {
  background-color: #d8b22e;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
}

.chatbot-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  max-height: calc(100% - 150px);  /* Responsive height for messages area */
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
}

.message.user {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message.bot {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.chatbot-questions {
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chatbot-questions button {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  border: none;
  background-color: #d8b22e;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
}

.chatbot-questions button:hover {
  background-color: #d8b22e;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #d8b22e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1001;
  transition: transform 0.3s ease;
}

.chat-icon.hidden {
  display: none;
}

.chat-icon img {
  width: 105px;
  height: 85px;
}

.chatbot-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chatbot-navigation button {
  padding: 10px;
  background-color: #d8b22e;
  color: white;
  border: none;
  cursor: pointer;
}

.chatbot-navigation button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.email-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #e6e6e6;
}

.email-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.email-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.email-form button {
  padding: 10px;
  background-color: #d8b22e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.email-form button:hover {
  background-color: #d8b22e;
}

.chatbot-container .whatsapp-button {
  background-color: #25D366 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-align: left;
}

.chatbot-container .whatsapp-button:hover {
  background-color: #128C7E !important;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Media Query for Small Screens */
@media (max-width: 600px) {
  .chatbot-container {
    width: 90%;
    height: 60vh;
    max-width: 100%;
  }

  .chat-icon {
    width: 65px;
    height: 65px;
  }

  .email-form input {
    font-size: 14px;
  }
}
